<template>
  <v-app>
    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'AuthHome',
  head: {
    titleTemplate: 'Continue to Bubbl'
  }
};
</script>

<style scoped>
body {
  background-color: white;
}

.v-main {
  height: 100vh;
}

.text-purple {
  color: #795fdc;
}

.bg-purple {
  background-color: #795fdc;
}
</style>
